import React, { useContext } from "react";
import { Link, graphql } from "gatsby";
import { FirebaseContext } from "../../components/Firebase";
import { sluggify } from "../../helpers/sluggify";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import Seo from "../seo";
import Header from "../organisms/Header";
import Navigator from "../organisms/Navigator";
import Copyright from "../atoms/Copyright";
import Loader from "../atoms/Loader";
import Footer from "../organisms/Footer";

const AreaInfo = (props) => {

  const { user } = useContext(FirebaseContext);

  const area = props.data.area,
        information = props.data.allMarkdownRemark.edges[0].node.html;

  return (
    <>
      {!user ? (
        <Loader />
      ) : (
        <>
          <Seo title={area.name + ' Information'} />
          <Header color={area.color}>
            <Navigator guideName={area.guide.name} areaName={area.name} title="General Information" />
            {area.guide.name === 'Montagu' && user.montaguAccess && (
              <Link to={`/${sluggify(area.guide.name)}/search`} className="search-icon">
                <span>Search</span>
                <FontAwesomeIcon icon={ faSearch } size={ 'lg' } />
              </Link>
            )}
          </Header>
          <main>
            <div className="container">
              <div className="information" dangerouslySetInnerHTML={{ __html: information }} />
              <Copyright />
            </div>
          </main>
          <Footer />
        </>
      )}
    </>
  )
}

export const query = graphql`
  query AreaInfoQuery($areaId: String!) {
    area(id: {eq: $areaId}) {
      name
      color
      guide {
        name
      }
    }
    allMarkdownRemark(filter: {frontmatter: {relation: {eq: $areaId}}}) {
      edges {
        node {
          html
        }
      }
    }
  }
`;

export default AreaInfo;